@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';

.pb-key-heading {
    h1, h2, h3, h4 {
        font-size: 40px !important;
        line-height: 1.2em;
        span {
            color: $zovpurple;
        }
    }
    h3 {
        font-size: clamp(30px, 4vw, 73px) !important;
    }
    sup {
        margin-top: 4em;
        color: #909090;
        font-size: 11px;
        display: inline-block;
    }
    &__centre {
        text-align: center;
        .inner {
            margin-left: auto;
            margin-right: auto;
        }
    }
    @include bpr-media-breakpoint(down, md) {
        &__left {
            .inner {
                max-width: none;
                text-align: center;
            }
        }
	}
    @include bpr-media-breakpoint(down, sm) {
        h1, h2, h3, h4 {
            font-size: 21px !important;
        }
	}
}